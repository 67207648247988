import React from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Box, Typography, Step, StepLabel, Stepper, styled} from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import DiscussionIcon from '@mui/icons-material/Forum';
import WorkIcon from '@mui/icons-material/Work';

const steps = [
  {
    label: 'Book a free discovery call',
    description:
      "Fill out the contact form with some high level details about what you're looking for and we will get back to you within 24 hours to book a discovery call.",
    Icon: CallIcon,
  },
  {
    label: 'Discuss solutions and agree on project specifications',
    description:
      'We outline your project requirements and propose a path forward.',
    Icon: DiscussionIcon,
  },
  {
    label: 'Begin the work and show progress through demonstrations',
    description:
      'Our team starts the work, providing regular updates and demos.',
    Icon: WorkIcon,
  },
];

// Custom StepLabel for larger text and custom font
const CustomStepLabel = styled(StepLabel)(({theme}) => ({
  '& .MuiStepLabel-label': {
    fontSize: '1.25rem', // Larger font size for step labels
  },
  '& .MuiTypography-caption': {
    fontSize: '1rem', // Larger font size for captions
  },
}));

const OurProcess: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{width: '100%', my: 4}}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        How We Work
      </Typography>
      <Stepper
        alternativeLabel
        activeStep={-1}
        orientation={matches ? 'vertical' : 'horizontal'}
      >
        {steps.map(step => (
          <Step key={step.label}>
            <CustomStepLabel
              icon={<step.Icon />}
              optional={
                <Typography variant="caption">{step.description}</Typography>
              }
            >
              {step.label}
            </CustomStepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default OurProcess;
