import React from 'react';
import {Box, Typography, Grid, Avatar} from '@mui/material';

const AboutUs: React.FC = () => {
  const imageUrl = '/self_portrait.jpg'; // Update this path to your image's location

  return (
    <Box my={4} sx={{width: '100%', backgroundColor: '#CC5500'}}>
      <Grid container spacing={2} alignItems="center">
        {' '}
        {/* Reduced spacing for closer alignment */}
        {/* Self-portrait image as a circle aligned to the left */}
        <Grid item xs={12} md={4}>
          {' '}
          {/* Adjusted grid sizing for image */}
          <Avatar
            src={imageUrl}
            alt="Founder Portrait"
            sx={{
              width: 'auto', // Adjust size as needed
              height: 'auto', // Ensure width and height are equal for a perfect circle
              maxWidth: 300,
              maxHeight: 300,
              display: 'block', // Ensures the Avatar is centered in its Grid item
              mx: 'auto', // Centers the Avatar horizontally within its grid item
              mb: 2, // Adds margin bottom on smaller screens when stacked
            }}
          />
        </Grid>
        {/* Text content closer to the image */}
        <Grid item xs={12} md={8}>
          <Typography variant="h4" gutterBottom>
            About Sig Software
          </Typography>
          <Typography paragraph sx={{mr: 3}}>
            Hi, I&apos;m Cody, the founder of Sig Software. I am driven by a
            passion for entrepreneurship and software development. With a
            background in computer science and seven years in corporate America
            as a software engineer, I have embarked on a mission to empower
            small businesses to get results through software.
          </Typography>
          <Typography paragraph sx={{mr: 3}}>
            My goal is this: amplify your online presence in a competitive
            digital era. I specialize in building landing pages that serve as
            traffic funnels for your business. Let&apos;s grow together.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
