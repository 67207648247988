import React from 'react';
import {Box, Typography, Grid} from '@mui/material';
import Lottie from 'lottie-react';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import DataIntegration from '../../resources/Data_Animation.json';

const DetailedDataAutomation: React.FC = () => {
  const controls = useAnimation();
  const {ref, inView} = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const textVariant = {
    hidden: {x: -100, opacity: 0},
    visible: {
      x: 0,
      opacity: 1,
      transition: {duration: 1.0},
    },
  };

  const lottieVariant = {
    hidden: {x: 100, opacity: 0},
    visible: {
      x: 0,
      opacity: 1,
      transition: {duration: 1.0},
    },
  };

  return (
    <Box sx={{my: 4}}>
      <Grid container spacing={2} alignItems="center">
        {/* Left side - Text Content */}
        <Grid item xs={12} md={6} sx={{overflowX: 'hidden'}}>
          <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={textVariant}
          >
            <Typography variant="h4" gutterBottom align="center">
              Bridging the Gap with Data Automation
            </Typography>
            <Typography paragraph align="center">
              In today&apos;s digital landscape, the ability to integrate and
              automate data flows between tools and domains is not just a
              convenience—it&apos;s a competitive advantage.
            </Typography>
            <Typography paragraph align="center">
              Our data automation services are designed to bridge the gap
              between disparate systems. Whether it&apos;s transferring data
              from one domain to another or translating data for different
              tools, we ensure a seamless, automated process that enhances
              efficiency, reduces manual errors, and saves valuable time.
            </Typography>
            <Typography paragraph align="center">
              Let us help you automate your data workflows, enabling your
              business to focus on what it does best while we take care of the
              integration complexities.
            </Typography>
          </motion.div>
        </Grid>

        {/* Right side - Graphic Illustration Placeholder */}
        <Grid item xs={12} md={6} sx={{overflowX: 'hidden'}}>
          <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={lottieVariant}
          >
            <Lottie animationData={DataIntegration} />
          </motion.div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailedDataAutomation;
