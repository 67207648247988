// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCyPAtLjbuDSMTyCgKfn1jtQAWW7gk9jfU',
  authDomain: 'sigsoftware.firebaseapp.com',
  projectId: 'sigsoftware',
  storageBucket: 'sigsoftware.appspot.com',
  messagingSenderId: '204677182943',
  appId: '1:204677182943:web:918b693f60102229dcc06a',
  measurementId: 'G-132P6D0G0Y',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
