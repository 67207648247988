import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';

const OurServices: React.FC = () => {
  const theme = useTheme();
  return (
    <Box my={4} sx={{width: '100%'}}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: '25%',
            height: '1px',
            backgroundColor: theme.palette.divider,
            mx: 1,
            marginBottom: 1,
          }}
        />
        <Typography variant="h4" component="h2" textAlign="center" gutterBottom>
          Our Services
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: '25%',
            height: '1px',
            backgroundColor: theme.palette.divider,
            mx: 1,
            marginBottom: 1,
          }}
        />
      </Box>
      <Grid
        container
        spacing={2}
        justifyContent="space-around"
        textAlign="center"
      >
        {[
          {
            title: 'Landing Pages',
            description:
              'This is our specialty, we call it "The Traffic Tunnel." We build SEO focused, responsive landing pages to drive traffic to your business.',
            link: '#landing-page-details',
          },
          {
            title: 'Project Management',
            description:
              "You're a business person. We're software people. We offer first class project management and liasion services between your business and a development team.",
            link: '#project-management-details',
          },
          {
            title: 'Data Automation',
            description:
              "System A performs one task and System B performs another task, but they don't work together. Does this sound familiar? We can bridge the gap.",
            link: '#data-automation-details',
          },
        ].map(service => (
          <Grid item xs={12} sm={4} key={service.title}>
            <Card>
              <CardActionArea href={service.link}>
                <CardContent>
                  <Typography variant="h5" component="h3" gutterBottom>
                    {service.title}
                  </Typography>
                  <Box
                    component="h4"
                    sx={{
                      height: 'auto',
                      minHeight: '100px',
                      listStyle: 'none',
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Typography variant="body1">
                      {service.description}
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OurServices;
