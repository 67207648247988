import React from 'react';
import {Box, Typography, Grid} from '@mui/material';
import Lottie from 'lottie-react';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import ProjectManagement from '../../resources/PM_Animation.json';

const DetailedProjectManagement: React.FC = () => {
  const controls = useAnimation();
  const {ref, inView} = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const textVariant = {
    hidden: {opacity: 0},
    visible: {
      x: 0,
      opacity: 1,
      transition: {duration: 1.0},
    },
  };

  const lottieVariant = {
    hidden: {opacity: 0},
    visible: {
      x: 0,
      opacity: 1,
      transition: {duration: 1.0},
    },
  };

  return (
    <Box sx={{my: 4}}>
      <Grid container spacing={2} alignItems="center">
        {/* Left side - Graphic Illustration Placeholder */}
        <Grid item xs={12} md={6}>
          <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={lottieVariant}
          >
            <Lottie animationData={ProjectManagement} />
          </motion.div>
        </Grid>

        {/* Right side - Text Content */}
        <Grid item xs={12} md={6}>
          <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={textVariant}
          >
            <Typography variant="h4" gutterBottom align="center">
              Let us handle the software...
            </Typography>
            <Typography paragraph align="center">
              Launching a software product into production demands considerable
              effort and can swiftly become an overwhelming drain on your time—a
              situation you&apos;d undoubtedly prefer to avoid. We&apos;re here
              to take charge of your project, steering it to successful
              completion with our seasoned experience in collaborating with
              developers globally. Entrust us with your development needs,
              freeing you to concentrate fully on your business priorities.
              Let&apos;s partner together to transform your vision into reality,
              while you dedicate your attention where it&apos;s needed most: on
              growing your business.
            </Typography>
          </motion.div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailedProjectManagement;
