import React, {useState} from 'react';
import {Grid, TextField, Button, Box, Typography} from '@mui/material';
import {getFunctions, httpsCallable} from 'firebase/functions';
import app from './../../firebaseConfig';

const ContactForm: React.FC = () => {
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  const functions = getFunctions(app);
  const sendEmail = httpsCallable(functions, 'sendEmail');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setContactForm({...contactForm, [name]: value});
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const templateParams = {
      from_name: contactForm.name,
      from_email: contactForm.email,
      phone_number: contactForm.phoneNumber,
      message: contactForm.message,
      to_name: 'Cody',
    };

    try {
      await sendEmail(templateParams);
      setContactForm({name: '', email: '', phoneNumber: '', message: ''});
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Box textAlign="center" mb={2}>
        <Typography variant="h5" component="h3" gutterBottom>
          Ready to get your project going?
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="name"
            fullWidth
            label="Name"
            variant="outlined"
            value={contactForm.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="email"
            fullWidth
            label="Email"
            variant="outlined"
            type="email"
            value={contactForm.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="phoneNumber"
            fullWidth
            label="Phone Number"
            variant="outlined"
            type="tel"
            value={contactForm.phoneNumber}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="message"
            fullWidth
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            value={contactForm.message}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: '#CC5500', // Custom color
                '&:hover': {
                  backgroundColor: '#b24c00', // Darker shade for hover state
                },
              }}
              fullWidth
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactForm;
