import React from 'react';
import {Box, Grid, Typography, Link} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  return (
    <Box sx={{backgroundColor: '#f5f5f5', padding: '20px', marginTop: '30px'}}>
      <Grid container spacing={3}>
        {/* Logo Placeholder */}
        <Grid item xs={12} sm={4}>
          <Box
            component="img"
            sx={{
              height: '50px',
              width: 'auto',
            }}
            src="/black_logo.svg"
            alt="Logo"
          />
        </Grid>

        {/* Links to Sections */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6">Services</Typography>
          <Link
            href="#landing-page-details"
            sx={{display: 'block', textDecoration: 'none', color: 'inherit'}}
          >
            Landing Pages
          </Link>
          <Link
            href="#project-management-details"
            sx={{display: 'block', textDecoration: 'none', color: 'inherit'}}
          >
            Project Management
          </Link>
          <Link
            href="#data-automation-details"
            sx={{display: 'block', textDecoration: 'none', color: 'inherit'}}
          >
            Data Automation
          </Link>
          {/* Add more links as needed */}
        </Grid>

        {/* Contact Information Placeholder */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6">Contact Us</Typography>
          <Typography variant="body2">
            Email: cody.sigvartson@gmail.com
          </Typography>
          <Typography variant="body2">Phone: (425) 359-9720</Typography>
          <Typography variant="h6">Find Us On</Typography>
          <Link
            href="https://www.facebook.com/profile.php?id=61556637436323"
            target="_blank"
            rel="noopener noreferrer"
            sx={{marginRight: '10px'}}
          >
            <FacebookIcon />
          </Link>
          <Link
            href="https://www.linkedin.com/in/YourProfile"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
