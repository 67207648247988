import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import Lottie from 'lottie-react';
import SEO from '../../resources/SEO_Animation.json';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';

const DetailedLandingPage: React.FC = () => {
  const controls = useAnimation();
  const {ref, inView} = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const textVariant = {
    hidden: {x: -100, opacity: 0},
    visible: {
      x: 0,
      opacity: 1,
      transition: {duration: 1.0},
    },
  };

  const lottieVariant = {
    hidden: {x: 100, opacity: 0},
    visible: {
      x: 0,
      opacity: 1,
      transition: {duration: 1.0},
    },
  };

  return (
    <Box sx={{my: 4}}>
      <Grid container spacing={2} alignItems="center">
        {/* Left side - Text Content */}
        <Grid item xs={12} md={6} sx={{overflowX: 'hidden'}}>
          <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={textVariant}
          >
            <Typography variant="h4" gutterBottom align="center">
              Landing Pages
            </Typography>
            <Typography paragraph align="center">
              What is it? Well, you&apos;re on one! A landing page is more than
              just a web page; it&apos;s a targeted platform designed to convert
              visitors into customers. It guides visitors through your business
              offerings, focusing on a singular goal, be it lead generation,
              sales, or event registration. A landing page on its own is nothing
              special. It requires Search Engine Optimization (SEO) to maximixe
              your visibility on the web and serve as what we like to call a
              &quot;Traffic Funnel.&quot;
            </Typography>
          </motion.div>
        </Grid>

        {/* Right side - Placeholder for Graphic */}
        <Grid item xs={12} md={6} sx={{overflowX: 'hidden'}}>
          <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={lottieVariant}
          >
            <Lottie animationData={SEO} />
          </motion.div>
        </Grid>
      </Grid>

      {/* New section for "The Impact of SEO", centered */}
      <Box sx={{mt: 4}}>
        <Typography variant="h5" align="center" gutterBottom>
          The Impact of SEO
        </Typography>
        <Typography paragraph align="center">
          Search Engine Optimization (SEO) plays a pivotal role in ensuring your
          landing page is discovered by the right audience. By optimizing for
          relevant keywords, ensuring fast load times, and crafting compelling
          content, SEO helps improve visibility, driving more targeted traffic
          to your page.
        </Typography>
        <Typography paragraph align="center">
          A well-optimized landing page not only ranks higher in search results
          but also enhances user experience, making it more likely for visitors
          to take the desired action. This strategic approach turns your landing
          page into a powerful &quot;traffic tunnel,&quot; effectively
          channeling potential clients directly to what they need, with minimal
          distractions.
        </Typography>
        <Typography paragraph align="center">
          Investing in a high-quality landing page with a strong SEO foundation
          can significantly benefit your business by increasing conversion
          rates, generating leads, and ultimately boosting sales. It&apos;s an
          essential component of any successful online marketing strategy,
          acting as the cornerstone of digital engagement with your brand.
        </Typography>
      </Box>
    </Box>
  );
};

export default DetailedLandingPage;
