import React from 'react';
import './App.css';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import LandingPage from './components/pages/LandingPage';

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: ['Poppins'].join(','),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <LandingPage />
      </div>
    </ThemeProvider>
  );
}

export default App;
