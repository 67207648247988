import React from 'react';
import {Container, Grid, Box, Typography} from '@mui/material';
import ContactForm from '../ui-section/ContactForm';
import OurServices from '../ui-section/OurServices';
import OurProcess from '../ui-section/OurProcess';
import AboutUs from '../ui-section/AboutUs';
import DetailedLandingPage from '../ui-section/DetailedLandingPage';
import DetailedProjectManagement from '../ui-section/DetailedProjectManagement';
import DetailedDataAutomation from '../ui-section/DetailedDataAutomation';
import Footer from '../ui-section/Footer';

const LandingPage: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          maxWidth: '100%',
          backgroundImage: 'url(/seattle_city.webp)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Logo Position Adjustment */}
          <Box sx={{alignSelf: 'flex-start', paddingTop: 5}}>
            <img
              src="/white_logo.svg"
              alt="Logo"
              style={{
                width: '100%',
                maxWidth: '400px', // Maximum logo width for larger screens
                height: 'auto',
              }}
            />
          </Box>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} md={6}>
              {/* Slogan Stretch Adjustment */}
              <Typography
                variant="h3"
                sx={{
                  maxWidth: 'none', // Removes maxWidth restriction
                  color: 'white',
                }}
              >
                Helping deliver business results through software solutions.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{paddingBottom: 5}}>
              <Box
                sx={{
                  backgroundColor: '#fff', // White background
                  p: 3, // Responsive padding: smaller on xs, larger on sm and above
                  borderRadius: 2, // Rounded corners
                  boxShadow: 3, // Shadow for depth
                  maxWidth: '100%', // Prevents overflow
                  mx: 'auto', // Center align
                  my: 2, // Margin top and bottom for spacing
                  width: 'auto', // Auto width to fit content
                }}
              >
                <ContactForm />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* Full-width section for OurServices */}
      <Box sx={{mx: {xs: 2, sm: 3, md: 4}}}>
        <Box sx={{my: 4}}>
          <OurServices />
        </Box>

        <AboutUs />

        <Box id="landing-page-details" sx={{my: 4}}>
          <DetailedLandingPage />
        </Box>

        <Box id="project-management-details" sx={{my: 4}}>
          <DetailedProjectManagement />
        </Box>

        <Box id="data-automation-details" sx={{my: 4}}>
          <DetailedDataAutomation />
        </Box>

        <OurProcess />
      </Box>
      <Footer />
    </>
  );
};

export default LandingPage;
